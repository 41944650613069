import React, { useState, useEffect } from 'react';
import { get } from '../services/ApiService';

const Dashboard = () => {
  const [missingSkus, setMissingSkus] = useState([]);
  const [missingPips, setMissingPips] = useState([]);
  const [pendingGoodsCount, setPendingGoodsCount] = useState(0);

  useEffect(() => {
    fetchMissingSkus();
    fetchMissingPips();
    fetchPendingGoodsCount();
  }, []);

  const fetchMissingSkus = async () => {
    try {
      const response = await get('api/sku/missingsku');
      setMissingSkus(response.data);
    } catch (error) {
      console.error('Error fetching missing SKUs:', error);
    }
  };

  const fetchMissingPips = async () => {
    try {
      const response = await get('api/missingPips');
      setMissingPips(response.data);
    } catch (error) {
      console.error('Error fetching missing PIPs:', error);
    }
  };

  const fetchPendingGoodsCount = async () => {
    try {
      const response = await get('api/invoices');
      const pendingGoods = response.data.filter((item) => item.received === 'PENDING');
      setPendingGoodsCount(pendingGoods.length);
    } catch (error) {
      console.error('Error fetching pending goods count:', error);
    }
  };

  return (
    <div>
      <h1>Dashboard</h1>
      
      <div className="dashboard-section">
        <h2>Missing SKUs</h2>
        <ul>
          {missingSkus.length > 0 ? (
            missingSkus.map((sku) => (
                <>
                <li key={`${sku.title}`}>{sku.title} -- <b>{sku.sku}</b></li>
                </>
            ))
          ) : (
            <p>No missing SKUs.</p>
          )}
        </ul>
      </div>

      <div className="dashboard-section">
        <h2>Missing PIPs</h2>
        <ul>
          {missingPips.length > 0 ? (
            missingPips.map((pip) => <><li key={pip.item}>{pip.item} -- <b>{pip.pip}</b></li></>)
          ) : (
            <p>No missing PIPs.</p>
          )}
        </ul>
      </div>

      <div className="dashboard-section">
        <h2>Pending Goods</h2>
        <h3>Total Pending Goods: {pendingGoodsCount}</h3>
      </div>
    </div>
  );
};

export default Dashboard;
