import React from 'react';

const TopBar = ({ user, leftBarCollapsed, setLeftBarCollapsed }) => {
  return (
    <div className={`top-bar ${leftBarCollapsed ? 'left-bar-collapsed' : ''}`}>
      <div className="logo">
        <img src="https://pillpharm.co.uk/assets/customer/images/logo-black.png" alt="Logo" />
      </div>
      <div className='heading'><h1>Inventory</h1></div>
      <div className="user">
        {user ? `Welcome, ${user}` : 'Login'}
      </div>
    </div>
  );
};

export default TopBar;