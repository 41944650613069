import React, { useState } from 'react';
import { post } from '../services/ApiService';

const Login = ({ onLogin, onClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // New loading state

  const handleLogin = async () => {
    setLoading(true); // Start loading
    setError(''); // Clear previous errors

    try {
      const response = await post('api/login', { username, password });
      if (response.data.success) {
        onLogin(username);
      } else {
        setError('Invalid username or password');
      }
    } catch (error) {
      console.error('Error occurred during login:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Trigger login on Enter key press
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleClose = () => {
    setError('');
    setUsername('');
    setPassword('');
    onClose();
  };

  return (
    <div className="login-modal">
      <div className="login-content">
        <h2>Login</h2>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={handleKeyDown} // Use onKeyDown here
            disabled={loading} // Disable inputs while loading
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown} // Use onKeyDown here
            disabled={loading} // Disable inputs while loading
          />
        </div>
        <button onClick={handleLogin} disabled={loading}>
          {loading ? 'Loading...' : 'Login'}
        </button>
        {loading && (
          <div className="loading-spinner">
            {/* Replace with actual loading GIF */}
            <img src="/orange_circles.gif" alt="Loading..." />
          </div>
        )}
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button onClick={handleClose} disabled={loading}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Login;
