import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import TableInvoices from './pages/TableInvoices';
import PendingInvoices from './pages/PendingInvoices';
import TableSales from './pages/TableSales';
import TableStockLost from './pages/TableStockLost';
import TableStockCount from './pages/TableStockCount';
import TableStockReplenished from './pages/TableStockReplenished';
import AddProducts from './pages/addProducts';
import TableSku from './pages/TableSku';
import Dashboard from './pages/Dashboard';
import Layout from './components/Layout'; // Import the Layout component

// import '../app/globals.css';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Your initialization code that relies on the browser environment
    }
  }, [location]); // Added location dependency to re-run effect on route changes

  return null; // Home component doesn't need to return anything since it's used for the useEffect
};

const App = () => (
  <Router>
    <Layout>
      <Routes>
      <Route path="/" element={<TableStockCount />} />
      <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/TableStockCount" element={<TableStockCount />} />
        <Route path="/TableInvoices" element={<TableInvoices />} />
        <Route path="/PendingInvoices" element={<PendingInvoices />} />
        <Route path="/TableStockLost" element={<TableStockLost />} />
        <Route path="/TableStockReplenished" element={<TableStockReplenished />} />
        <Route path="/addProducts" element={<AddProducts />} />
        <Route path="/tableSku" element={<TableSku />} />
        {/* Add routes for other tab contents */}
      </Routes>
    </Layout>
  </Router>
);

export default App;
