import React, { useState, useEffect } from 'react';
import { get, post,put, del } from '../services/ApiService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillTrash3Fill,BsPencilSquare,BsSearch,BsXCircle,BsCheckCircle   } from "react-icons/bs";
import Select from 'react-select';

// import Select from 'react-select';

const AddProducts = ({ onUpdateItem, onDeleteItem }) => {
  const [showPopupPIP, setShowPopupPIP] = useState(false);
  const [showPopupProduct, setShowPopupProduct] = useState(false);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchFilters, setSearchFilters] = useState({});
  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [itemOptions, setItemOptions] = useState([]);
  const [PPIDOptions, setPPIDOptions] = useState([]);
  const [dataPIP, setDataPIP] = useState([]);
  const [missingPips, setMissingPips] = useState([]);
  const [newPPID, setNewPPID] = useState({
    product: '',
    type: '',
    pip: '',
    medical_classification: '',
    weight_g: '',
  });

  const [newPIP, setNewPIP] = useState({
    product: '',
    ppid:'',
    pip: '',
  });
  const [updateItem, setUpdateItem] = useState({
    product: '',
    type: '',
    pip: '',
    medical_classification: '',
    weight_g: ''
  });
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [filterColumn, setFilterColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchDataAndSetOptions = async () => {
      const responsePPID = await fetchData('ppid');
      setMissingPips(await fetchMissingPipData());

      console.log('missinggpips: ',missingPips);
      const filteredOptions = responsePPID.filter((item) => item.type != 'null');
      setPPIDOptions(filteredOptions.map((item) => ({ id: item.id, product: item.product })));

      setDataPIP(await fetchData('pip'));

    };

    fetchDataAndSetOptions();
  }, []);

  const validateData = (Data) => {
    const missingValues = [];

    console.log(Data)
  
    for (const key in Data) {
      if (!Data[key] || Data[key] === '') {
        missingValues.push(key);
      }
    }
  
    if (missingValues.length > 0) {
      alert(`Please fill in the following fields: ${missingValues.join(', ')}`);
      return false;
    }
  
    return true;
  };

  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortDirection('asc');
    }
  };

  const handleUpdateButtonClick = (item) => {
    //console.log(item);
    setSelectedItemId(item.id);
    setUpdateItem({ ...item }); // Ensure 'updateItem' is populated correctly
    

  };

  const handleDeleteItem = async (id) => {
    try {
      // Display confirmation dialog
      const isConfirmed = window.confirm("Are you sure you want to delete this item?");

      if (isConfirmed) {
        await del(`api/invoices/pip?id=${id}`);
        fetchData(); // Refresh data after deleting
        onDeleteItem(); // Notify parent component about the deletion
      }
    } catch (error) {
      console.error('Error deleting invoice:', error);
    }
  };

  const handleSearch = (e, column) => {
    const { value } = e.target;
    setSearchFilters(prevFilters => ({ ...prevFilters, [column]: value }));
  };

  const handleColumnHeaderClick = (columnKey) => {
    if (!searchIconClicked) {
      handleSort(columnKey);
    }
  };
  

  const handleSearchIconClick = (columnKey) => {
    // setSortedColumn(null); // Reset sorting
    setFilterColumn(columnKey);
    setShowFilterPopover(true);
    setShowCalendar(columnKey === 'date');
    setSearchIconClicked(true); // Set search icon clicked
  };

  const handleFilterPopoverClose = () => {
    setShowFilterPopover(false);
    setSearchIconClicked(false); // Reset search icon clicked
    setSearchFilters('');
  };

  const handleDateSelect = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    setSearchFilters(prevFilters => ({ ...prevFilters, date: formattedDate }));
    setShowCalendar(false); // Hide the calendar after selection
  };

  const filteredData = dataPIP.filter(item => {
    return Object.entries(searchFilters).every(([key, value]) => {
      if (!value || key === 'id') return true; // Exclude 'id' from search
      if (key === 'date') {
        const itemDate = new Date(item[key]).toISOString().split('T')[0];
        const searchDate = new Date(value).toISOString().split('T')[0];
        return itemDate.includes(searchDate);
      }
      return String(item[key]).toLowerCase().includes(value.toLowerCase());
    });
  });

  const sortedData = sortedColumn
    ? filteredData.slice().sort((a, b) => {
      const valueA = a[sortedColumn];
      const valueB = b[sortedColumn];
      if (sortedColumn === 'date') {
        return sortDirection === 'asc' ? new Date(valueA) - new Date(valueB) : new Date(valueB) - new Date(valueA);
      }
      if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    })
    : filteredData.slice();


  const fetchData = async (tab) => {
    try {
      const response = await get(`api/ppidProductInfo/${tab}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  const fetchMissingPipData = async (tab) => {
    try {
      const response = await get(`api/missingPips`);
      return response.data;
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };


  const handleAdditem = async (tab) => {

    try {
      if (tab === 'pip') {

        if (!validateData(newPIP)) {
          console.log(newPIP.pip)
            return;
        }
        

        if (dataPIP.some((item) => item.pip == newPIP.pip)) {
          alert('The PIP already exists. Please check again.');
          return;
        }

        // const [splitNewPid, splitNewItem] = newPIP.product.split(' - ');
        // const newSplitPip = {
        //   ...newPIP,
        //   ppid: splitNewPid,
        //   product: splitNewItem,
        // };

        console.log(newPIP)

        await post(`api/ppidProductInfo/${tab}`, newPIP);
        setNewPIP({
          product: '',
          pip: '',
        });
        setDataPIP(await fetchData('pip'));

      } else if (tab === 'ppid') {


        if (!validateData(newPPID)) {

            return;
        }

        if (dataPIP.some((item) => item.pip == newPPID.pip)) {
          alert('The PIP already exists. Please check again.');
          return;
        }

        
        const newPPIDValues = {
            product: newPPID.product.concat(` - (${newPPID.medical_classification})`),
            type: newPPID.type,
            medical_classification: newPPID.medical_classification,
          };

        await post('api/ppidProductInfo/ppid', newPPIDValues);

        const responseAutoIncrement = await fetchData('auto_increment');
        console.log(responseAutoIncrement)
        const currentPPID = responseAutoIncrement.autoIncrement;


        const newPIPValues = {
          ppid: currentPPID,
          product: newPPID.product.concat(` - (${newPPID.medical_classification})`),
          type: newPPID.type,
          pip: newPPID.pip,
        };

        const newWeightValues = {
          ppid: currentPPID,
          product: newPPID.product.concat(` - (${newPPID.medical_classification})`),
          weight_g: newPPID.weight_g,
        };


        await post('api/ppidProductInfo/pip', newPIPValues);
        await post('api/ppidProductInfo/weight', newWeightValues);

        setDataPIP(await fetchData('pip'));
        setNewPPID({
          product: '',
          type: '',
          pip: '',
          medical_classification: '',
          weight_g: '',
        });
      }
      const responsePPID = await fetchData('ppid');
      setPPIDOptions(responsePPID.map((item) => ({ id: item.id, product: item.product })));
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  const handleUpdateitem = async (tab) => {

    await post(`api/ppidProductInfo/pip?id=${"id"}`, {"some": "data"});

  };

  const filteredOptions = itemOptions.filter(item => item.product.toLowerCase().includes(searchTerm.toLowerCase()));
  
  // Update search term when input changes
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '15px',
      color: 'black',
      padding: '5px',
      borderWidth: '0px',
      width: '500px'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 0, // remove border radius
    }),
    option: (provided, state) => ({
      ...provided,
      width: '500px',
      fontSize: '14px'
      // backgroundColor: state.isSelected ? '#007bff' : 'transparent', // highlight selected option
      // color: state.isSelected ? '#fff' : '#000', // text color for selected and non-selected options
    }),
  };


  return (
    <div>
      <h2>Add an additional PIP for an existing generic product</h2>


      <div className="add-invoice">
      <button className="add-invoice-button" onClick={() => setShowPopupPIP(true)}>
        + Add an new PIP for generic
      </button>
    </div>

    {showPopupPIP && (

<div className="popup">
<div className="popup-inner">
  <div className="update-form">
      <label className='form-labels'>
        PPID - Product:
        <div className="select-dropdown">

        <Select
value={
  newPIP.product && newPIP.ppid
  ? { value: `${newPIP.ppid} - ${newPIP.product}`, label: `pp${newPIP.ppid} - ${newPIP.product}` }
  : { value: "", label: "Select item" }
}
onChange={(selectedOption) => setNewPIP({ ...newPIP, product: selectedOption.product, ppid: selectedOption.ppid })}
options={PPIDOptions.map(item => ({ value: `${item.id} - ${item.product}`, label: `pp${item.id} - ${item.product}`, product: item.product, ppid: item.id }))}
styles={customStyles}
/>
</div>
        {/* <select
         className='form-fields'
          value={newPIP.product}
          onChange={(e) => setNewPIP({ ...newPIP, product: e.target.value })}
        >
          <option value="">Select an item</option>
          {PPIDOptions.map((item) => (
            <option key={item.product} value={`${item.id} - ${item.product}`}>
            {`${item.id} - ${item.product}`}
            </option>
          ))} */}
        {/* </select> */}
      </label>

      <label className='form-labels'>
        PIP:
        <input
         className='form-fields'
          type="text"
          value={newPIP.pip}
          onChange={(e) => setNewPIP({ ...newPIP, pip: e.target.value })}
        />
      </label>

          <div>
      <button className="update-button" onClick={() => handleAdditem('pip')}>
             <BsCheckCircle />
            </button>
            <button className="close-button" onClick={() => setShowPopupPIP(false)}>
           < BsXCircle />
          </button>
          </div>
          </div>
      </div>
      </div>
    )}


      <h2>Add new product</h2>


<div className="add-invoice">
      <button className="add-invoice-button" onClick={() => setShowPopupProduct(true)}>
        + Add new product to database
      </button>
    </div>

    {showPopupProduct && (

<div className="popup">
<div className="popup-inner">
  <div className="update-form">
      <label className='form-labels'>
        Product Name:
        <input className='form-fields'
          type="text"
          value={newPPID.product}
          onChange={(e) => setNewPPID({ ...newPPID, product: e.target.value })}
        />
      </label>

      <label className='form-labels'>
        Type:
        <select className='form-fields'
          value={newPPID.type}
          onChange={(e) => setNewPPID({ ...newPPID, type: e.target.value })}
        >
         <option value="">Select an item</option>
          <option value="branded">Branded</option>
          <option value="generic">Generic</option>
        </select>
      </label>

      <label className='form-labels'>
        Medical Classification:
        <select className='form-fields'
          value={newPPID.medical_classification}
          onChange={(e) => setNewPPID({ ...newPPID, medical_classification: e.target.value })}
        >
          <option value="">Select an item</option>
          <option value="GSL">GSL</option>
          <option value="P">P</option>
          <option value="POM">POM</option>
        </select>
      </label>

      <label className='form-labels'>
        PIP:
        <input className='form-fields'
          type="text"
          value={newPPID.PIP}
          onChange={(e) => setNewPPID({ ...newPPID, pip: e.target.value })}
        />
      </label>

      <label className='form-labels'>
        Weight (g):
        <input className='form-fields'
          type="number"
          value={newPPID.weight_g}
          onChange={(e) => setNewPPID({ ...newPPID, weight_g: e.target.value })}
        />
      </label>
<div>
      <button className="update-button" onClick={() => handleAdditem('ppid')}>
             <BsCheckCircle />
            </button>
            <button className="close-button" onClick={() => setShowPopupProduct(false)}>
           < BsXCircle />
          </button>
          </div>

</div>
</div>
</div>)}

      <h2>DataPIP Table</h2>
      <div className='table_back'>


      <table>
      <thead>
          <tr>
            {dataPIP.length > 0 &&
              Object.keys(dataPIP[0]).map((key) =>
                key !== 'id' ? (
<th key={key} >
  <div onClick={() => handleColumnHeaderClick(key)}>
  {key}{' '}
  {!searchIconClicked && sortedColumn === key && (sortDirection === 'asc' ? '▲' : '▼')}

  </div>

  <div>
  
  <button className="search-icon" onClick={() => handleSearchIconClick(key)}>
    < BsSearch  className="icon-search"/>
    </button>

  </div>
  {filterColumn === key && showFilterPopover && (
    <div className="popover">
      {key !== 'date' ? (
        <input
          type="text"
          placeholder={`Search ${key}`}
          value={searchFilters[key] || ''}
          onChange={(e) => handleSearch(e, key)}
        />
      ) : (
        <DatePicker
          selected={searchFilters.date ? new Date(searchFilters.date) : null}
          onChange={handleDateSelect}
          dateFormat="yyyy-MM-dd"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      )}
      <button onClick={handleFilterPopoverClose}><BsXCircle className="icon-search"/></button>
    </div>
  )}
</th>
                ) : null
              )}
              <th>Actions</th>
           
          </tr>
        </thead>
        <tbody>
  {sortedData.map((item) => (
    <tr key={item.id}>
      {Object.keys(item).map((key) =>
        key !== 'id' ? (
          <td key={key}>{key === 'date' ? new Date(item[key]).toISOString().split('T')[0] : item[key]}</td>
        ) : null
      )}
      <td>
        <button className="action-button" onClick={() => handleUpdateButtonClick(item)}>
          <BsPencilSquare />
        </button>
        <button className="action-button" onClick={() => handleDeleteItem(item.id)}>
          <BsFillTrash3Fill />
        </button>
      </td>
    </tr>
  ))}
</tbody>

      </table> 
      </div>
      <h2>Missing PIPS - To be added to database</h2>
      <div className='table_back'>
      <table>
      <thead>
          <tr>
            {missingPips.length > 0 &&
              Object.keys(missingPips[0]).map((key) =>
                key !== 'id' ? (
<th key={key} >
  {key}
</th>
                ) : null
              )}
                </tr>
               <tbody>
  {missingPips.map((item) => (
                <tr>
      {Object.keys(item).map((key) => (
        <td key={key}>
          {item[key]}
        </td>
      ))}
    </tr>
  ))}
</tbody>
            </thead>
      </table>
      </div>
    </div>

  );
};

export default AddProducts;
