import axios from 'axios';


// const BASE_URL = process.env.BASE_URL;
// const BASE_URL = "http://localhost:5000";
const BASE_URL = "https://inventory-server.myprivatechemist.com";

// Function to handle GET requests
async function get(endpoint) {
  const url = `${BASE_URL}/${endpoint}`;
  try {
    const response = await axios.get(url);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

// Function to handle POST requests
async function post(endpoint, body) {
  const url = `${BASE_URL}/${endpoint}`;
  console.log(url);
  try {
    const response = await axios.post(url, body);
    console.log(response);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw new Error('Error posting data: ' + error.message);
  }
}

// Function to handle POST requests
async function put(endpoint, body){
    const url = `${BASE_URL}/${endpoint}`;
    try {
      const response = await axios.put(url,body);
      if (response.status !== 200 && response.status !== 201) {
        throw new Error('Network response was not ok');
      }
      return response;
    } catch (error) {
      console.error('Error posting data:', error.message);
      throw new Error(error);
    }
  }

async function del(endpoint) {
  const url = `${BASE_URL}/${endpoint}`;
  try {
    const response =  await axios.delete(url);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error) {
    console.error('Error deleting data:', error.message);
    throw new Error(error);
  }
}



export { get, post,put, del };