import React, { useState, useEffect } from 'react';
import { BsHouse , BsTag  , BsArrowsCollapseVertical, BsBarChartLine, BsFileEarmarkText, BsHourglassSplit, BsClipboard2Minus, BsClipboard2Plus, BsDatabaseAdd, BsBoxArrowRight } from "react-icons/bs";
import { Link, useLocation } from 'react-router-dom';

const LeftBar = ({ leftBarCollapsed, setLeftBarCollapsed, handleLogout }) => {
  const location = useLocation();

  // Use local storage or default to 'stockCount'
  const [selectedTab, setSelectedTab] = useState(() => {
    if (typeof window !== 'undefined') {
      const storedTab = localStorage.getItem('selectedTab');
      return storedTab || 'stockCount'; // Default to 'stockCount'
    }
    return 'stockCount';
  });

  useEffect(() => {
    const currentPath = location.pathname;
    
    // Adjust based on currentPath
    switch (currentPath) {
      case '/Dashboard':
        setSelectedTab('dashboard');
        localStorage.setItem('selectedTab', 'dashboard'); // Persist state on path change
        break;
      case '/TableStockCount':
        setSelectedTab('stockCount');
        localStorage.setItem('selectedTab', 'stockCount'); // Persist state on path change
        break;
      case '/TableInvoices':
        setSelectedTab('invoices');
        localStorage.setItem('selectedTab', 'invoices');
        break;
      case '/PendingInvoices':
        setSelectedTab('pending');
        localStorage.setItem('selectedTab', 'pending');
        break;
      case '/TableStockLost':
        setSelectedTab('stockLost');
        localStorage.setItem('selectedTab', 'stockLost');
        break;
      case '/TableStockReplenished':
        setSelectedTab('stockReplenished');
        localStorage.setItem('selectedTab', 'stockReplenished');
        break;
      case '/addProducts':
        setSelectedTab('addProducts');
        localStorage.setItem('selectedTab', 'addProducts');
        break;
      case '/tableSku':
          setSelectedTab('tableSku');
          localStorage.setItem('selectedTab', 'tableSku');
          break;
      default:
        setSelectedTab('stockCount'); // Default to StockCount if home path or other paths
        localStorage.setItem('selectedTab', 'stockCount');
        break;
    }
  }, [location.pathname]);

  return (
    <div className={`left-bar ${leftBarCollapsed ? 'collapsed' : ''}`}>
      <div className="collapse-btn" onClick={() => setLeftBarCollapsed(!leftBarCollapsed)}>
        <BsArrowsCollapseVertical className='icon-collapse' />
      </div>
      <ul>
      <Link to="/Dashboard">
          <li className={selectedTab === 'dashboard' ? 'selected-tab' : ''} onClick={() => setSelectedTab('dashboard')}>
            <span className="icon">
              <BsHouse  className={selectedTab === 'dashboard' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Dashboard'}
          </li>
        </Link>
        <Link to="/TableStockCount">
          <li className={selectedTab === 'stockCount' ? 'selected-tab' : ''} onClick={() => setSelectedTab('stockCount')}>
            <span className="icon">
              <BsBarChartLine className={selectedTab === 'stockCount' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Stock Count'}
          </li>
        </Link>
        <Link to="/TableInvoices">
          <li className={selectedTab === 'invoices' ? 'selected-tab' : ''} onClick={() => setSelectedTab('invoices')}>
            <span className="icon">
              <BsFileEarmarkText className={selectedTab === 'invoices' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'All Invoices'}
          </li>
        </Link>
        <Link to="/PendingInvoices">
          <li className={selectedTab === 'pending' ? 'selected-tab' : ''} onClick={() => setSelectedTab('pending')}>
            <span className="icon">
              <BsHourglassSplit className={selectedTab === 'pending' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Pending Goods'}
          </li>
        </Link>
        <Link to="/TableStockLost">
          <li className={selectedTab === 'stockLost' ? 'selected-tab' : ''} onClick={() => setSelectedTab('stockLost')}>
            <span className="icon">
              <BsClipboard2Minus className={selectedTab === 'stockLost' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Stock Lost'}
          </li>
        </Link>
        <Link to="/TableStockReplenished">
          <li className={selectedTab === 'stockReplenished' ? 'selected-tab' : ''} onClick={() => setSelectedTab('stockReplenished')}>
            <span className="icon">
              <BsClipboard2Plus className={selectedTab === 'stockReplenished' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Stock Replenished'}
          </li>
        </Link>
        <Link to="/addProducts">
          <li className={selectedTab === 'addProducts' ? 'selected-tab' : ''} onClick={() => setSelectedTab('addProducts')}>
            <span className="icon">
              <BsDatabaseAdd className={selectedTab === 'addProducts' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Add Products'}
          </li>
        </Link>
        <Link to="/TableSku">
          <li className={selectedTab === 'tableSku' ? 'selected-tab' : ''} onClick={() => setSelectedTab('tableSku')}>
            <span className="icon">
              <BsTag  className={selectedTab === 'tableSku' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Product SKUs'}
          </li>
        </Link>
        <Link to="#" onClick={handleLogout}>
          <li className={selectedTab === 'logout' ? 'selected-tab' : ''} onClick={() => setSelectedTab('logout')}>
            <span className="icon">
              <BsBoxArrowRight className={selectedTab === 'logout' ? 'selected-tab-icon' : ''} />
            </span>
            {!leftBarCollapsed && 'Logout'}
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default LeftBar;
