import React, { useState, useEffect } from 'react';
import TopBar from './TopBar';
import LeftBar from './LeftBar';
import Login from './login';
// import "../index.css";

const Layout = ({ children }) => {
  const [user, setUser] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(true);
  const [leftBarCollapsed, setLeftBarCollapsed] = useState(false);

  useEffect(() => {
    // Check if user credentials are already saved
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      setUser(savedUser);
      setShowLoginModal(false);
    }
  }, []);

  const handleLogin = (username) => {
    setUser(username);
    setShowLoginModal(false);
    // Save user credentials
    localStorage.setItem('user', username);
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user'); // Remove saved user credentials on logout
  };

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  return (
    
    <>
      {user && (
        <TopBar
          user={user}
          leftBarCollapsed={leftBarCollapsed}
          setLeftBarCollapsed={setLeftBarCollapsed}
        />
      )}
      <div className="container">
        {user && (
          <LeftBar
            leftBarCollapsed={leftBarCollapsed}
            setLeftBarCollapsed={setLeftBarCollapsed}
            handleLogout={handleLogout}
          />
        )}
        <div className="main-content">
          {user ? (
            children
          ) : (
            <Login onLogin={handleLogin} onClose={handleCloseLoginModal} />
          )}
        </div>
      </div>
    </>
  );
};

export default Layout;
